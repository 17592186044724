
import Vue from "vue";
import IAccountTypesBundler from "@/lib/interfaces/account-types-bundler";
import AccountTypesBundlersMaintenance from "@/components/subviews/account-types-bundlers/account-types-bundlers-maintenance.vue";
import AccountTypesBundlersList from "@/components/subviews/account-types-bundlers/account-types-bundlers-list.vue";

export default Vue.extend({
  name: "AccountTypesBundlers",

  components: { AccountTypesBundlersList, AccountTypesBundlersMaintenance },

  data() {
    return {
      refresh: 0,

      currentTab: 0,

      accountTypesBundler: {} as IAccountTypesBundler,
    };
  },
});
