
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import VForm from "@/lib/types/v-form";
import { Rq, Table } from "@/lib/amdt";
import WorgError from "@/lib/worg-error";
import { maxLength, required } from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import IAccountTypesBundler from "@/lib/interfaces/account-types-bundler";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ACard from "@/components/elements/a-card.vue";

export default Vue.extend({
  name: "AccountTypesBundlersMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ACard,
  },

  props: {
    accountTypesBundler: {
      type: Object as PropType<IAccountTypesBundler>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",
      search: "",

      accountsTypes: new Array<IAccountTypesBundler>(),

      dataAccountTypesBundler: {
        id: "",
        descricao: "",
        desativado: "0",
      } as IAccountTypesBundler,
    };
  },

  watch: {
    accountTypesBundler: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataAccountTypesBundler = {
          ...this.accountTypesBundler,
        };
      },
    },
  },

  computed: {
    cTextSave(): string {
      return this.dataAccountTypesBundler.id ? "Alterar" : "Salvar";
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },
  },

  methods: {
    required,
    maxLength,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_conta_agrupador.write");

        const tblAccountTypesBundlers = new Table("tipo_conta_agrupador");

        const obj = {
          ...this.dataAccountTypesBundler,
          id: this.dataAccountTypesBundler.id || "default",
        };
        tblAccountTypesBundlers.setColsFromObject(obj);
        tblAccountTypesBundlers.addRowsFromObject(obj);

        rq.addTable(tblAccountTypesBundlers);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe um agrupador com esse nome";
          return;
        }

        this.ret_msg = `Grupo ${
          this.dataAccountTypesBundler.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataAccountTypesBundler.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataAccountTypesBundler = {
        id: "",
        descricao: "",
        desativado: "0",
      };

      this.form.resetValidation();
    },
  },
});
